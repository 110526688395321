<template>
	<b-modal id="print-maintenance-summary" :title="title" size="lg" ok-title="Download" ref="modal" @ok="handleDownload"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-container ref="maintenanceSummary" v-show="selMaintenance !== null">
			<div class="maintenance-summary">
				<b-row class="my-2">
				<b-col sm="6">
					<div class="upper-header">Maintenance Report</div>
					<div class="maintenance-id">
						Maintenance ID: {{ selMaintenance.maintenanceId }}
					</div>
					<div class="upper-header">
						{{ showFormattedDate(selMaintenance.dateCreated) }}
					</div>
				</b-col>
				<b-col sm="6" align="right">
					<img v-img-orientation-changer src="img/logo.png" class="img-logo" alt="Ayun! Logo" />
				</b-col>
			</b-row>

			<b-row class="my-4">
				<b-col sm="7" class="highlight-section">
					<b-row no-gutters>
						<b-col sm="5" class="section-header">
							PRIMARY INFORMATION
						</b-col>
						<b-col sm="7">
							<hr />
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col sm="5" class="form-field"> COMPANY: </b-col>
						<b-col sm="7" class="form-value">
							{{ selMaintenance.company }}
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col sm="5" class="form-field">
							STORAGE LOCATION:
						</b-col>
						<b-col sm="7" class="form-value">
							{{ selMaintenance.storageLocation }}
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col sm="5" class="form-field"> ASSET TYPE: </b-col>
						<b-col sm="7" class="form-value">
							{{ selMaintenance.assetType }}
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col sm="5" class="form-field"> CREATED BY: </b-col>
						<b-col sm="7" class="form-value">
							{{ selMaintenance.createdBy }}
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col sm="5" class="form-field"> STATUS: </b-col>
						<b-col sm="7" class="form-value">
							{{ selMaintenance.status }}
						</b-col>
					</b-row>
				</b-col>
				<b-col sm="5">
					<b-row no-gutters>
						<b-col sm="6" class="section-header">
							SCAN TO VIEW IN APP
						</b-col>
						<b-col sm="6">
							<hr />
						</b-col>
					</b-row>
					<b-row>
						<b-col class="ml-4" sm="7">
							<qr-code :size="100" :text="selMaintenance.maintenanceId" />
						</b-col>
					</b-row>
					<b-row></b-row>
				</b-col>
				<b-col sm="5"> </b-col>
			</b-row>

			<b-row class="my-4">
				<b-col sm="7">
					<b-row no-gutters>
						<b-col sm="4" class="section-header">
							APPROVAL FORMS
						</b-col>
						<b-col sm="8">
							<hr />
						</b-col>
					</b-row>

					<b-row>
						<b-col sm="5" class="form-field">
							CLIENT APPROVAL:
						</b-col>
						<b-col sm="7" class="form-value">
							{{ getMediaName(selMaintenance.clientApprovalForm) }}
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col sm="5" class="form-field"> CAPEX: </b-col>
						<b-col sm="7" class="form-value">
							{{ getMediaName(selMaintenance.capexForm) }}
						</b-col>
					</b-row>
				</b-col>

				<b-col sm="5">
					<b-row no-gutters>
						<b-col sm="5" class="section-header">
							CLASSIFICATION
						</b-col>
						<b-col sm="7">
							<hr />
						</b-col>
					</b-row>

					<b-row>
						<b-col sm="6" class="form-field"> REPAIR: </b-col>
						<b-col sm="5" class="form-value">
							{{ selMaintenance.repairCount }}
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col sm="6" class="form-field"> DISPOSE: </b-col>
						<b-col sm="5" class="form-value">
							{{ selMaintenance.disposedCount }}
						</b-col>
					</b-row>
				</b-col>
			</b-row>

			<b-row class="my-4">
				<b-col sm="7">
					<b-row no-gutters>
						<b-col sm="4" class="section-header">
							REPAIRED BATCHES
						</b-col>
						<b-col sm="8">
							<hr />
						</b-col>
					</b-row>

					<b-row class="mt-0">
						<b-col sm="5" class="form-field"> REPAIRED: </b-col>
						<b-col sm="7" class="form-value">
							{{
								getTotalRepaired(selMaintenance.repairedBatches)
							}}
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col sm="5" class="form-field"> CHECKED: </b-col>
						<b-col sm="7" class="form-value">
							{{
								getTotalChecked(selMaintenance.repairedBatches)
							}}
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col sm="5" class="form-field"> ENDORSED: </b-col>
						<b-col sm="7" class="form-value">
							{{
								getTotalEndorsed(selMaintenance.repairedBatches)
							}}
						</b-col>
					</b-row>
				</b-col>

				<b-col sm="5">
					<b-row no-gutters>
						<b-col sm="5" class="section-header">
							BATCH DETAILS
						</b-col>
						<b-col sm="7">
							<hr />
						</b-col>
					</b-row>

					<b-row class="mt-0">
						<b-col sm="6" class="form-field">
							TOTAL BATCHES:
						</b-col>
						<b-col sm="5" class="form-value">
							{{ getBatchCount(selMaintenance.repairedBatches) }}
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col sm="6" class="form-field">
							IN PRODUCTION:
						</b-col>
						<b-col sm="5" class="form-value">
							{{
								getInProduction(selMaintenance.repairedBatches)
							}}
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col sm="6" class="form-field"> IN QC: </b-col>
						<b-col sm="5" class="form-value">
							{{ getInQC(selMaintenance.repairedBatches) }}
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col sm="6" class="form-field"> IN WAREHOUSE: </b-col>
						<b-col sm="5" class="form-value">
							{{ getInWarehouse(selMaintenance.repairedBatches) }}
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			</div>
			<b-row class="my-2 footer">
				<b-col sm="3" align="left">
					<img src="img/tawitech_logo_white.png" width="30" height="30" />
				</b-col>
				<b-col sm="9" align="right" style="margin-top: 5px">
					Diezmo Rd., Brgy., Pulo, Cabuyao, Laguna, Philippines, 4025
					| 02 519 4042 | 0917 869 8576
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { MaintenanceUtil } from '@/utils/maintenanceUtil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import html2canvas from 'html2canvas';

export default {
	name: 'print-maintenance-summary',
	components: {
		Loading,
	},
	data() {
		return {
			selMaintenance: {},
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Print Maintenance ' + this.selMaintenance.maintenanceId;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelMaintenance', async (selMaintenance) => {
			this.isLoading = true;

			if (!_.isEmpty(selMaintenance)) {
				this.selMaintenance =
					MaintenanceUtil.cleanupFields(selMaintenance);
			}

			this.isLoading = false;
		});
	},
	methods: {
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},

		getMediaName(media) {
			return (media && media.name) ? media.name : '';
		},

		getTotalRepaired(batches) {
			return MaintenanceUtil.getRepairedAssetCount(batches);
		},

		getTotalChecked(batches) {
			return MaintenanceUtil.getCheckedAssetCount(batches);
		},

		getTotalEndorsed(batches) {
			return MaintenanceUtil.getEndorsedAssetCount(batches);
		},

		getInProduction(batches) {
			return MaintenanceUtil.getInProduction(batches);
		},

		getInQC(batches) {
			return MaintenanceUtil.getInQC(batches);
		},

		getInWarehouse(batches) {
			return MaintenanceUtil.getInWarehouse(batches);
		},

		getBatchCount(batches) {
			return batches == null ? 0 : batches.length;
		},

		getFileName() {
			let company = this.selMaintenance.company;
			let storageLocation = this.selMaintenance.storageLocation;
			let area = company + '-' + storageLocation;

			let maintenanceId = this.selMaintenance.maintenanceId;

			return maintenanceId + '_' + area + '.png';
		},
		async handleDownload(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// Show loader
			this.isLoading = true;

			window.scroll(0, 0);

			let filename = this.getFileName();
			let canvas = await html2canvas(this.$refs.maintenanceSummary);

			const link = document.createElement('a');
			link.setAttribute('download', filename);
			link.setAttribute(
				'href',
				canvas
					.toDataURL('image/png')
					.replace('image/png', 'image/octet-stream')
			);
			link.click();

			this.$refs.modal.hide();
			// Hide loader
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelMaintenance');
	},
};
</script>

<style scoped>
.img-logo {
	margin-left: -30px;
	width: 32%;
}

.upper-header {
	opacity: 0.5;
	text-transform: uppercase;
	color: #111118;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
}

.maintenance-id {
	text-transform: uppercase;
	color: #122c91;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	align-items: center;
}

.footer {
	font-size: 12px;
	text-transform: capitalize;
	margin-top: 40px;
	color: white;
	background-color: #122c91;
}

.section-header {
	font-size: 13px;
	margin-top: 11px;
	line-height: 9px;
	color: #122c91;
	font-weight: bold;
	text-transform: uppercase;
	text-align: left !important;
}

.form-field {
	font-size: 13px;
	text-align: right;
	text-transform: uppercase;
	line-height: 15px;
}

.form-value {
	font-size: 13px;
	font-weight: bold;
	text-align: left;
	text-transform: uppercase;
	line-height: 15px;
}

.highlight-section {
	background: #fff6e9;
	padding-bottom: 20px;
	border-radius: 10px;
	border: 1.5px solid #f18f01;
}

.maintenance-summary {
	padding: 1em;
}
</style>
