<template>
	<div>
		<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
			@click.stop="row.toggleDetails" class="mr-1 mt-1">
			<em class="fa fa-eye-slash" v-if="row.detailsShowing"></em>
			<em class="fa fa-eye" v-else></em>
		</b-button>

		<b-button v-show="isVisible(['Open'])" 
			size="sm" v-b-tooltip.hover.top="'Edit Maintenance'" variant="dark"
			@click.stop="editMaintenance(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<em class="fa fa-pencil"></em>
		</b-button>

		<b-button v-show="isVisible(['Open', 'In-Repair'])" 
			size="sm" v-b-tooltip.hover.top="'Repair Maintenance'" variant="warning"
			@click.stop="repairMaintenance(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<em class="fa fa-wrench"></em>
		</b-button>

		<b-button v-show="isVisible(['In-Repair'])" size="sm" v-b-modal.complete-maintenance
			v-b-tooltip.hover.top="'Complete Maintenance'" variant="primary"
			@click.stop="updateSelMaintenance(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<em class="fa fa-check"></em>
		</b-button>

		<b-button v-show="isCancellable(row.item, ['Open', 'In-Repair'])" size="sm" v-b-modal.cancel-maintenance
			v-b-tooltip.hover.top="'Cancel Maintenance'" variant="danger"
			@click.stop="updateSelMaintenance(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<em class="fa fa-ban"></em>
		</b-button>

		<b-button size="sm" v-b-modal.print-maintenance-summary v-b-tooltip.hover.top="'Print Maintenance Summary'"
			variant="dark" @click.stop="updateSelMaintenance(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<em class="icon-printer"></em>
		</b-button>
	</div>
</template>

<script>
import EventBus from '@/shared/event-bus';

export default {
	name: 'maintenance-row-actions',
	props: {
		row: {
			type: Object,
			required: true,
		},
		isSuperAdmin: {
			type: Boolean,
			required: true,
		},
		isViewer: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			allCompaniesObj: this.$store.getters.companies,
			allConnectedCompaniesObj: this.$store.getters.connectedCompanies,
			allStorageLocationsObj: this.$store.getters.storageLocations,
			allConnectedStorageLocationsObj: this.$store.getters.connectedStorageLocations,
			allConnectionsObj: this.$store.getters.connections,
			allUsersObj: this.$store.getters.users,
			allAssetTypesObj: this.$store.getters.assetTypes,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
		};
	},
	computed: {
		status() {
			return this.row && this.row.item.status;
		},
	},
	methods: {
		editMaintenance(item, _index, _button) {
			// init parameters
			let params = {
				allCompaniesObj: this.allCompaniesObj,
				allStorageLocationsObj: this.allStorageLocationsObj,
				allConnectionsObj: this.allConnectionsObj,
				allUsersObj: this.allUsersObj,
				allAssetTypesObj: this.allAssetTypesObj,
				allMaintenancesObj: this.allMaintenancesObj,
			};

			this.$store.dispatch('setMaintenanceParams', params);
			this.$store.dispatch('setCurrentMaintenance', item);

			if (this.isSuperAdmin) {
				this.$router.push({ path: '/admin/admin-edit-maintenance' });
			} else if (!this.isSuperAdmin) {
				this.$router.push({ path: '/edit-maintenance' });
			} else {
				this.$toaster.warning('Invalid Source Screen');
			}
		},
		repairMaintenance(item, _index, _button) {
			
			this.$store.dispatch('setCurrentMaintenance', item);
			if (item.status !== 'In-Repair') {				
				this.$store.commit('SET_CURR_MAINTENANCE', item);
				EventBus.$emit('onRepairSelMaintenance', item);
				this.$bvModal.show('proceed-to-repair-maintenance');
			} else {
				if (this.isSuperAdmin) {
					this.$router.push({ path: '/admin/admin-repair-maintenance' });
				} else if (!this.isSuperAdmin) {
					this.$router.push({ path: '/repair-maintenance' });
				} else {
					this.$toaster.warning('Invalid Source Screen');
				}
			}
		},
		updateSelMaintenance(item, _index, _button) {
			this.$store.commit('SET_CURR_MAINTENANCE', item);
			EventBus.$emit('onUpdateSelMaintenance', item);
		},
		isVisible(status) {
			const allowedStatuses = [status[0], status[1]];
			return allowedStatuses.includes(this.status) && !this.isViewer;
		},
		isCancellable(maintenance, status) {
			const allowedStatuses = [status[0], status[1]];
			const hasNoRepairedBatches = maintenance.repairedBatches.length === 0;

			return allowedStatuses.includes(this.status) && !this.isViewer && hasNoRepairedBatches;
		},

	},
};
</script>

<style scoped></style>

