import config from '../config/env-constants';


export const MaintenanceUtil = {

	getCompanyLocationDisplay(item) {
		return item.company + ' (' + item.storageLocation + ')';
	},

	getRepairedAssetCount(batches) {
		let total = 0;
		if (batches != null){
			for (const batch of batches) {
				if (batch.status !== config.batchStatus.CANCELLED.name && batch.repairedBy) {
					total += (batch.repaired - batch.rejected);
				}
			}
		}
		return total;
	},

	getCheckedAssetCount(batches) {
		let total = 0;
		if (batches != null){
			for (const batch of batches) {
				if (batch.status !== config.batchStatus.CANCELLED.name && batch.checkedBy) {
					total += batch.good;
				}
			}
		}
		return total;
	},

	getEndorsedAssetCount(batches) {
		let total = 0;
		if (batches != null){
			for (const batch of batches) {
				if (batch.status !== config.batchStatus.CANCELLED.name && batch.endorsedBy) {
					total += batch.good;
				}
			}
		}
		return total;
	},

	getInProduction(batches) {
		let total = 0;
		if (batches != null){
			for (const batch of batches) {
				if (batch.status === config.batchStatus.IN_PRODUCTION.name) {
					total++;
				}
			}
		}
		return total;
	},

	getInQC(batches) {
		let total = 0;
		if (batches != null){
			for (const batch of batches) {
				if (batch.status === config.batchStatus.IN_QC.name) {
					total++;
				}
			}
		}
		return total;
	},

	getInWarehouse(batches) {
		let total = 0;
		if (batches != null){
			for (const batch of batches) {
				if (batch.status === config.batchStatus.IN_WAREHOUSE.name) {
					total++;
				}
			}
		}
		return total;
	},
	
	cleanupFields(maintenance) {
		let cleanedMaintenance = Object.assign({}, maintenance);

		delete cleanedMaintenance['area'];
		delete cleanedMaintenance['repaired'];
		delete cleanedMaintenance['checked'];
		delete cleanedMaintenance['endorsed'];
		delete cleanedMaintenance['Date Updated'];

		return cleanedMaintenance;
	},
}
