<template>
	<div class="animated fadeIn">
		<b-card>
			<b-card-title><i class="icon-wrench"></i> Maintenance</b-card-title>
			<b-card-sub-title>Manages the ongoing maintenances for all companies</b-card-sub-title>
			<b-container fluid class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>

					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Date From">
											<b-form-datepicker v-model="filterBy.dateFrom" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" :date-disabled-fn="dateFromDisabled" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Date To">
											<b-form-datepicker v-model="filterBy.dateTo" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" />
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text" :options="allCompanyOptions"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Storage Location">
											<v-select class="style-chooser" label="text" :options="storageLocationOptions"
												:reduce="(storage) => storage.value" v-model="filterBy.storageLocation">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Status">
											<b-form-select v-model="filterBy.status" :options="statusOptions"
												class="mr-2" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Asset Type">
											<v-select class="style-chooser" label="text" :options="allAssetTypesOptions"
												:reduce="(assetType) => assetType.value" v-model="filterBy.assetType">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Maintenance Id"
											description="NOTE: Input the exact maintenance id to search">
											<b-form-input id="maintenanceId" name="Maintenance Id" type="search"
												v-model="filterBy.maintenanceId"
												v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" />
											<span v-show="errors.has('Maintenance Id')" class="help-block">{{
												errors.first('Maintenance Id')
											}}</span>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item @click="addMaintenance" v-show="!isViewer">
								Add Maintenance
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
									Export Maintenances in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
									Export Maintenances to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>
					<template v-slot:cell(source)="row">
						<span class="location-display">
							{{ row.item.source.company }}
							<br />
							({{ row.item.source.storageLocation }})
						</span>
					</template>

					<template v-slot:cell(destination)="row">
						<span class="location-display">
							{{ row.item.destination.company }}
							<br />
							({{ row.item.destination.storageLocation }})
						</span>
					</template>

					<template v-slot:cell(dateCreated)="row">{{
						row.item.dateCreated
						? getDisplayDateTime(row.item.dateCreated)
						: '-'
					}}</template>

					<template v-slot:cell(dateReceived)="row">{{
						row.item.dateReceived
						? getDisplayDateTime(row.item.dateReceived)
						: '-'
					}}</template>

					<template v-slot:cell(status)="row">
						<MaintenanceRowStatus :row="row" />
					</template>

					<template v-slot:cell(actions)="row">
						<MaintenanceRowActions :row="row" :isSuperAdmin="isSuperAdmin" :isViewer="isViewer"
							parentComponent="Maintenance" />
					</template>

					<template slot="row-details" slot-scope="row">
						<MaintenanceDetailsView :row="row" :allUsersObj="allUsersObj" />
					</template>

				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</b-container>
		</b-card>

		<!-- Modals here -->
		<ImageViewDialog />
		<CompleteMaintenance />
		<ToRepairMaintenance />
		<CancelMaintenance />
		<PrintMaintenanceSummary />
	</div>
</template>

<script>
// Components
import MaintenanceRowStatus from '@/views/transactions/maintenance/MaintenanceRowStatus';
import MaintenanceRowActions from '@/views/transactions/maintenance/MaintenanceRowActions';
import MaintenanceDetailsView from '@/views/transactions/maintenance/MaintenanceDetailsView';
import CompleteMaintenance from '@/views/transactions/maintenance/CompleteMaintenance';
import ToRepairMaintenance from '@/views/transactions/maintenance/ToRepairMaintenance';
import CancelMaintenance from '@/views/transactions/maintenance/CancelMaintenance';
import ImageViewDialog from '@/views/transactions/common/ImageViewDialog';
import PrintMaintenanceSummary from '@/views/transactions/maintenance/PrintMaintenanceSummary';

// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { LocationUtil } from '@/utils/locationUtil';
import { MaintenanceUtil } from '@/utils/maintenanceUtil';

// API
import maintenanceApi from '@/api/maintenanceApi';

// DAO
import maintenanceDAO from '@/database/maintenances';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'maintenance',
	components: {
		MaintenanceRowStatus,
		MaintenanceRowActions,
		MaintenanceDetailsView,
		CompleteMaintenance,
		ToRepairMaintenance,
		CancelMaintenance,
		ImageViewDialog,
		PrintMaintenanceSummary,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'maintenanceId',
					sortable: true,
				},
				{
					key: 'area',
					label: 'Area',
				},
				{
					key: 'assetType',
					label: 'Asset Type',
				},
				{
					key: 'disposedCount',
					label: 'Disposed',
				},
				{
					key: 'repaired',
					label: 'Repaired',
				},
				{
					key: 'checked',
					label: 'Checked',
				},
				{
					key: 'endorsed',
					label: 'Endorsed',
				},
				{
					key: 'Date Updated',
				},
				{
					key: 'status'
				},
				'actions',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				company: config.companyDefaultValue,
				storageLocation: config.companyDefaultValue,
				status: 'Open',
				maintenanceId: '',
				assetType: config.assetTypeDefaultValue,
			},
			filterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				company: config.companyDefaultValue,
				storageLocation: config.companyDefaultValue,
				status: 'Open',
				maintenanceId: '',
				assetType: config.assetTypeDefaultValue,
			},
			prevFilterBy: {},

			allCompanyOptions: [],
			allAssetTypesOptions: [],
			storageLocationOptions: [],
			statusOptions: config.maintenanceStatus,

			allCompaniesObj: {},
			allConnectedCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectedStorageLocationsObj: {},
			allConnectionsObj: {},
			allUsersObj: {},
			allAssetTypesObj: {},
			allMaintenancesObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,

			// Listener
			maintenanceListener: null,
		};
	},
	watch: {
		'filterBy.company': function () {
			this.onChangeCompany();
		}
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */

		exportFields() {
			return {
				'Maintenance ID': 'maintenanceId',
				'Area': 'area',
				'Asset Type': 'assetType',
				'Disposed': 'disposedCount',
				'Repaired': 'repaired',
				'Checked': 'checked',
				'Endorsed': 'endorsed',
				'Status': 'status',
				'Description': 'description',
				'Date Created': 'dateCreated',
				'Date Updated': 'dateUpdated',
				'Date Cancelled': 'dateCancelled',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Maintenance-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// show loading indicator
				this.isLoading = true;

				this.updateParams();

				await this.retrieveData();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			this.resetStorageLocationOptions();

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseAddMaintenance', (maintenanceObj) => {
			this.updateTable(maintenanceObj);
		});
		EventBus.$on('onCloseEditMaintenance', (maintenanceObj) => {
			this.updateTable(maintenanceObj);
		});
		EventBus.$on('onCloseToRepairMaintenance', (maintenanceObj) => {
			this.updateTable(maintenanceObj);
		});
		EventBus.$on('onCloseCompleteMaintenance', (maintenanceObj) => {
			this.updateTable(maintenanceObj);
		});
		EventBus.$on('onCloseCancelMaintenance', (maintenanceObj) => {
			this.updateTable(maintenanceObj);
		});
	},
	methods: {

		listenerCallback(type, maintenance) {
			if ((!this.allMaintenancesObj[maintenance.id] && type === "added") || type === "modified") {
				this.allMaintenancesObj[maintenance.id] = maintenance;
				this.processMaintenances(this.allMaintenancesObj);
			}
		},

		resetStorageLocationOptions() {
			this.storageLocationOptions = [];
			this.storageLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});

			this.filterBy.storageLocation = config.storageLocationDefaultValue;
		},

		onChangeCompany() {
			// reset storage fields
			this.resetStorageLocationOptions();

			if (this.filterBy.company !== config.companyDefaultValue) {
				// retrieve the storage locations
				this.storageLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationOfConnectionItems(
						this.allConnectionsObj,
						this.allStorageLocationsObj,
						this.filterBy.company.id,
						this.filterBy.company.id
					);

				// pre-select the single option value
				if (this.storageLocationOptions.length == 2) {
					this.filterBy.storageLocation = this.storageLocationOptions[1].value;
				}
			}
		},

		updateParams() {
			this.allCompaniesObj = this.$store.getters.companies;
			this.allConnectedCompaniesObj = this.$store.getters.connectedCompanies;
			this.allStorageLocationsObj = this.$store.getters.storageLocations;
			this.allConnectedStorageLocationsObj = this.$store.getters.connectedStorageLocations;
			this.allConnectionsObj = this.$store.getters.connections;
			this.allUsersObj = this.$store.getters.users;
			this.allAssetTypesObj = this.$store.getters.assetTypes;

			// Create Source and Destination Company Options
			if (this.isSuperAdmin) {
				this.allCompanyOptions = DropDownItemsUtil.retrieveCompanyItems(
					this.allCompaniesObj, this.loggedUserCompany
				);
				this.storageLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationItems(this.allStorageLocationsObj);
			} else {
				this.allCompanyOptions = DropDownItemsUtil.retrieveCompanyItems(
					this.allConnectedCompaniesObj, this.loggedUserCompany
				);
				this.storageLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationItems(this.allStorageLocationsObj);

				this.allCompaniesObj = Object.assign(
					{},
					this.allCompaniesObj,
					this.allConnectedCompaniesObj
				);

				this.allStorageLocationsObj = Object.assign(
					{},
					this.allStorageLocationsObj,
					this.allConnectedStorageLocationsObj
				);
			}

			// parse geoaddress of storage location
			_.forEach(this.allStorageLocationsObj, function (value, _key) {
				value.geoaddress = LocationUtil.getGeoaddress(value.geoaddress);
			});

			// Create Asset Type Options
			this.allAssetTypesOptions = DropDownItemsUtil.retrieveAssetTypeItems(
				this.allAssetTypesObj
			);
		},
		updateTable(maintenanceObj) {
			if (_.isEmpty(maintenanceObj)) {
				return;
			}
			this.allMaintenancesObj[maintenanceObj.id] = maintenanceObj;

			let filteredObjs = Object.assign({}, this.allMaintenancesObj);
			_.forEach(this.allMaintenancesObj, (maintenance, id) => {
				let companyId = this.filterBy.company.id;
				if (
					!_.isEmpty(companyId) &&
					companyId !== maintenance.companyId
				) {
					delete filteredObjs[id];
				}

				let storageLocationId = this.filterBy.storageLocation.id;
				if (
					!_.isEmpty(storageLocationId) &&
					storageLocationId !== maintenance.storageLocationId
				) {
					delete filteredObjs[id];
				}

				let status = this.filterBy.status;
				if (status && status.length > 0 && status !== maintenance.status) {
					delete filteredObjs[id];
				}

				let maintenanceId = this.filterBy.maintenanceId;
				if (
					maintenanceId &&
					maintenanceId.length > 0 &&
					maintenanceId !== maintenance.maintenanceId
				) {
					delete filteredObjs[id];
				}

				let assetType = this.filterBy.assetType;
				if (
					assetType &&
					assetType.id &&
					assetType.id !== maintenance.assetTypeId
				) {
					delete filteredObjs[id];
				}
			});

			this.processMaintenances(filteredObjs);
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},

		validateFilter() {
			let isValid = true;

			if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning(
					'Invalid Date Range. Date From must be less than Date To.'
				);
				isValid = false;
			} else if (!ValidationUtil.isAlphaNumeric(this.filterBy.maintenanceId)) {
				this.$toaster.warning(
					'Invalid Maintenance ID. Please enter a valid Maintenance ID'
				);
				isValid = false;
			}

			return isValid;
		},

		onFilterRequest() {
			if (!this.validateFilter()) {
				return;
			}

			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},

		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = Object.assign({}, this.defaultFilterBy);
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},

		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = Object.assign({}, this.filterBy);
				filter.source = 'Maintenance';
				filter.fromTimestamp = DateUtil.startDateTimeStamp(
					new Date(filter.dateFrom)
				);
				filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

				if (this.isSuperAdmin) {
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await maintenanceApi.getMaintenances(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allMaintenancesObj = data.maintenances;
				} else {
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await maintenanceApi.getMaintenances(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allMaintenancesObj = data.maintenances;
				}

				this.processMaintenances(this.allMaintenancesObj);

				// Update listener
				this.maintenanceListener = maintenanceDAO.getMaintenanceListener(filter, this.listenerCallback);
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		processMaintenances(maintenances) {
			this.items = Object.values(maintenances);
			this.items = _.sortBy(this.items, ['dateCreated']);
			this.items.reverse();

			// status
			this.items.forEach((item) => {
				// Parse source and destination display

				item['area'] = MaintenanceUtil.getCompanyLocationDisplay(item);
				item['repaired'] = this.getTotalRepaired(item);
				item['checked'] = this.getTotalChecked(item);
				item['endorsed'] = this.getTotalEndorsed(item);
				item['Date Updated'] = this.getDisplayDateTime(item.dateUpdated);

			});
			this.totalRows = this.items.length;
			this.$store.dispatch('setAllMaintenances', this.allMaintenancesObj);
		},

		addMaintenance() {
			this.updateMaintenanceParam();
			this.$store.dispatch('setCurrentMaintenance', {});

			// redirect to Add Maintenance page
			if (this.isSuperAdmin) {
				this.$router.push({ path: '/admin/admin-add-maintenance' });
			} else {
				this.$router.push({ path: '/add-maintenance' });
			}
		},

		updateMaintenanceParam() {
			let params = {
				allCompaniesObj: this.allCompaniesObj,
				allStorageLocationsObj: this.allStorageLocationsObj,
				allConnectionsObj: this.allConnectionsObj,
				allUsersObj: this.allUsersObj,
				allAssetTypesObj: this.allAssetTypesObj,
				allMaintenancesObj: this.allMaintenancesObj,
			};

			this.$store.dispatch('setMaintenanceParams', params);
		},

		// UTILS
		getDisplayDateTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},

		getTotalRepaired(item) {
			return MaintenanceUtil.getRepairedAssetCount(item.repairedBatches);
		},

		getTotalChecked(item) {
			return MaintenanceUtil.getCheckedAssetCount(item.repairedBatches);
		},

		getTotalEndorsed(item) {
			return MaintenanceUtil.getEndorsedAssetCount(item.repairedBatches);
		}
	},

	beforeUnmount() {
		if (this.maintenanceListener != null) {
			// Stop listening to changes
			this.maintenanceListener();
		}
	},

	beforeDestroy() {
		EventBus.$off('onCloseAddMaintenance');
		EventBus.$off('onCloseEditMaintenance');
		EventBus.$off('onCloseToRepairMaintenance');
		EventBus.$off('onCloseCompleteMaintenance');
		EventBus.$off('onCloseCancelMaintenance');
	},
};
</script>
