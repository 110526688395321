import { db } from '@/config/firebase';

function generateQuery(filter) {
    let query = db.collection("maintenances");

    if (filter.maintenanceId && filter.maintenanceId.length > 0) {
        query = query.where("maintenanceId", "==", filter.maintenanceId);
    } else {
        if (filter.fromTimestamp && filter.fromTimestamp > 0) {
            query = query.where("dateCreated", ">=", filter.fromTimestamp);
        }

        if (filter.toTimestamp && filter.toTimestamp > 0) {
            query = query.where("dateCreated", "<=", filter.toTimestamp);
        }

        if (filter.company && filter.company.id && filter.company.id.length > 0) {
            query = query.where("companyId", "==", filter.company.id);
        }
        
        if (filter.storageLocation && filter.storageLocation.id && filter.storageLocation.id.length > 0) {
            query = query.where("storageLocationId", "==", filter.storageLocation.id);
        }

        if (filter.status && filter.status.length > 0) {
            query = query.where("status", "==", filter.status);
        }

        if (filter.assetType && filter.assetType.id && filter.assetType.id.length > 0) {
            query = query.where("assetTypeId", "==", filter.assetType.id);
        }
    }
    
    return query;
}

function getMaintenanceListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

export default {
    getMaintenanceListener
}