<template>
	<b-card>
		<b-tabs content-class="mt-3">
			<b-tab title="Primary Details" active>
				<b-card-text>
					<b-row class="my-12">
						<b-col class="mt-4" lg="6" md="6" sm="12">
							<b-form-group label="Company:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getArea }}
							</b-form-group>

							<b-form-group label="Asset Type:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.assetType }}
							</b-form-group>

							<b-form-group label="Status:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.status }}
							</b-form-group>

							<b-form-group label="Description:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<div v-if="row.item.description">
									<div :ref="'div' + row.item.maintenanceId" :style="{ width: '270px' }" class="truncate">
										{{ row.item.description }}
									</div>
									<a v-if="isEllipsisActive('div' + row.item.maintenanceId)"
										:ref="'a' + row.item.maintenanceId" href="#" @click.prevent="
											toggleNotes(
												'div' + row.item.maintenanceId,
												'a' + row.item.maintenanceId
											)
											">
										See more
									</a>
								</div>
							</b-form-group>

							<b-form-group label="Date Created:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ showFormattedDate(row.item.dateCreated) }}
							</b-form-group>

							<b-form-group label="Created By:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getUserDisplay(row.item.createdBy) }}
							</b-form-group>

							<b-form-group label="Date Updated:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ showFormattedDate(row.item.dateUpdated) }}
							</b-form-group>
							<b-form-group label="Updated By:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getUserDisplay(row.item.updatedBy) }}
							</b-form-group>

							<b-form-group v-show="row.item.status === 'Cancelled'" label="Date Cancelled:" label-cols-sm="4"
								label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ showFormattedDate(row.item.dateCancelled) }}
							</b-form-group>
							<b-form-group v-show="row.item.status === 'Cancelled'" label="Cancelled By:" label-cols-sm="4"
								label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ getUserDisplay(row.item.cancelledBy) }}
							</b-form-group>
						</b-col>
						<b-col lg="6" md="6" sm="12">
							<canvas id="classification-chart"></canvas>
						</b-col>
					</b-row>

				</b-card-text>
			</b-tab>

			<b-tab title="Disposal" v-if="row.item.disposedCount > 0">

				<b-card-text>
					<b-row>
						<b-col sm="12">
							<b-form-group>
								<b>Dispose Asset Photos</b>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="mb-4">
						<b-col :key="index" lg="2" md="4" sm="6" class="mb-2"
							v-for="(dispose, index) in row.item.disposals">
							<div v-if="hasMedia(dispose)">
								{{ dispose.name }}
								<br />
								<b-img v-img-orientation-changer :src="dispose.url" alt="Responsive image" thumbnail />
								<br />
								<a href="#" class="pull-left" @click.prevent="showImage(dispose.url)">
									Click to view full image
								</a>
							</div>
						</b-col>
					</b-row>

					<b-row class="mt-4">
						<b-col sm="12">
							<b-form-group label="Remarks:" label-cols-sm="12" label-class="font-weight-bold pt-0">
								{{ row.item.disposalRemarks }}
							</b-form-group>
						</b-col>
					</b-row>
				</b-card-text>

			</b-tab>

			<b-tab title="Approval Forms">
				<b-card-text>

					<b-row>
						<b-col lg="2" md="4" sm="6">
							<b-form-group>
								<b>Client Approval Form</b>
							</b-form-group>
						</b-col>
						<b-col lg="2" md="4" sm="6">
							<b-form-group>
								<b>CAPEX Form</b>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="mb-4">
						<b-col lg="2" md="4" sm="6">
							<div v-if="hasMedia(row.item.clientApprovalForm)">
								{{ row.item.clientApprovalForm.name }}
								<br />
								<b-img v-img-orientation-changer :src="row.item.clientApprovalForm.url"
									alt="Responsive image" thumbnail />
								<br />
								<a href="#" class="pull-left" @click.prevent="showImage(row.item.clientApprovalForm.url)">
									Click to view full image
								</a>
							</div>
						</b-col>

						<b-col lg="2" md="4" sm="6">
							<div v-if="hasMedia(row.item.capexForm)">
								{{ row.item.capexForm.name }}
								<br />
								<b-img v-img-orientation-changer :src="row.item.capexForm.url" alt="Responsive image"
									thumbnail />
								<br />
								<a href="#" class="pull-left" @click.prevent="showImage(row.item.capexForm.url)">
									Click to view full image
								</a>
							</div>
						</b-col>
					</b-row>

					<b-row class="mt-4">
						<b-col sm="12">
							<b-form-group label="Remarks:" label-cols-sm="12" label-class="font-weight-bold pt-0">
								{{ row.item.approvalFormRemarks }}
							</b-form-group>
						</b-col>
					</b-row>
				</b-card-text>

			</b-tab>



			<b-tab title="Repaired Batches" v-if="row.item.repairCount > 0">
				<b-card-text>

					<b-row class="mb-2 mt-2">
						<b-col sm="5">
							<b-form-group label="Total:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<span class="repair-count">{{ row.item.repairCount }}</span>
							</b-form-group>
						</b-col>
						<b-col sm="7">

							<b-form-group label="Repaired:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<b-progress :max="totalRepairCount" height="1rem">
									<b-progress-bar :value="totalRepaired">
										<span>
											<strong>{{ totalRepaired }} / {{ totalRepairCount }}</strong>
										</span>
									</b-progress-bar>
								</b-progress>
							</b-form-group>

							<b-form-group label="Checked:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<b-progress :max="totalRepairCount" height="1rem">
									<b-progress-bar :value="totalChecked">
										<span>
											<strong>{{ totalChecked }} / {{ totalRepairCount }}</strong>
										</span>
									</b-progress-bar>
								</b-progress>
							</b-form-group>

							<b-form-group label="Endosed:" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<b-progress :max="totalRepairCount" height="1rem">
									<b-progress-bar :value="totalEndorsed">
										<span>
											<strong>{{ totalEndorsed }} / {{ totalRepairCount }}</strong>
										</span>
									</b-progress-bar>
								</b-progress>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="mb-2 ml-3 mr-3">

						<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
							:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
							:sort-direction="sortDirection" responsive>
							<template v-slot:cell(actions)="row">
								<b-button v-show="hasUrl(row.item.turnOverFormUrl)" size="sm"
									v-b-tooltip.hover.top="'View Turn Over Form'" variant="success"
									@click.stop="showImage(row.item.turnOverFormUrl)" class="mr-1 mt-1">
									<em class="fa fa-image"></em>
								</b-button>
							</template>
						</b-table>

					</b-row>
					<b-row>
						<b-col md="8" sm="12" class="my-1">
							<span class="totalDisplay">Total: {{ totalRows }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
								class="my-0" size="sm" />
						</b-col>
					</b-row>

				</b-card-text>
			</b-tab>

		</b-tabs>

		<InvalidImageDialog />
	</b-card>
</template>

<script>
// Component
import InvalidImageDialog from '../common/InvalidImageDialog.vue';


// Util
import { DateUtil } from '@/utils/dateutil';
import { FileUtil } from '@/utils/fileUtil';
import { MaintenanceUtil } from '@/utils/maintenanceUtil';

// Others
import EventBus from '@/shared/event-bus';
import _ from 'lodash';
import Chart from 'chart.js';

export default {
	name: 'maintenance-details-view',
	props: {
		row: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'batchNo',
					label: 'Batch No.',
				},
				{
					key: 'repaired',
					label: 'Repaired',
					sortable: true,
				},
				{
					key: 'checked',
					label: 'Checked',
					sortable: true,
				},
				{
					key: 'endorsed',
					label: 'Endorsed',
					sortable: true,
				},
				{
					key: 'status',
				},
				{
					key: 'remarks'
				},
				'actions',
			],

			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			totalRepaired: 0,
			totalChecked: 0,
			totalEndorsed: 0,
			totalRepairCount: 0,

		}
	},
	components: {
		InvalidImageDialog,
	},
	computed: {
		getArea() {
			return MaintenanceUtil.getCompanyLocationDisplay(this.row.item);
		},
		total() {
			let item = this.row.item;
			return item.disposedCount + item.repairCount;
		}
	},

	async mounted() {

		let item = this.row.item;

		this.totalRepaired = MaintenanceUtil.getRepairedAssetCount(item.repairedBatches);
		this.totalChecked = MaintenanceUtil.getCheckedAssetCount(item.repairedBatches);
		this.totalEndorsed = MaintenanceUtil.getEndorsedAssetCount(item.repairedBatches);
		this.totalRepairCount = item.repairCount;

		const ctx = document.getElementById('classification-chart');

		new Chart(ctx, {
			type: 'pie',
			data: {
				labels: [
					'For Disposal (' + item.disposedCount + ')',
					'For Repair (' + item.repairCount + ')',
				],
				datasets: [{
					data: [
						item.disposedCount,
						item.repairCount
					],
					borderWidth: 1,
					backgroundColor: [
						'rgb(241, 143, 1)',
						'rgb(18, 43, 145)'
					],
				}]
			},
			options: {
				scales: {
					y: {
						beginAtZero: true
					}
				},
				legend: {
					position: 'top',
				},
				title: {
					display: true,
					text: 'Classification (' + this.total + ')'
				}
			}
		});

		this.processBatches();
	},

	methods: {
		processBatches() {
			let batches = this.row.item.repairedBatches;
			this.items = Object.values(batches);
			this.items = _.sortBy(this.items, ['batchNo']);

			this.items.forEach((item) => {

				// Repaired
				if (item.repairedBy) {
					item['repaired'] = item.good + item.rejected;
					item['remarks'] = item.repairedRemarks;
				} else {
					item['repaired'] = 0;
					item['remarks'] = '';
				}

				// Checked
				if (item.checkedBy) {
					item['checked'] = item.good + item.rejected;
					item['remarks'] = item.checkedRemarks;
				} else {
					item['checked'] = 0;
					item['remarks'] = '';
				}

				// Endorsed
				if (item.endorsedBy) {
					item['endorsed'] = item.good + item.rejected;
					item['remarks'] = item.endorsedRemarks;
				} else {
					item['endorsed'] = 0;
					item['remarks'] = '';
				}

			});
			this.totalRows = this.items.length;
		},

		getUserDisplay(userId) {
			let userObj = this.allUsersObj[userId];

			if (!_.isEmpty(userObj)) {
				return userObj.firstName + ' ' + userObj.lastName + ' (' + userId + ')';
			}
			return userId;
		},

		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},

		toggleNotes(divRefId, aRefId) {
			let elementArray = this.$refs[divRefId];
			if (elementArray) {
				if (elementArray instanceof Array) {
					let className = elementArray[0].className;
					if (className === 'truncate') {
						this.$refs[divRefId][0].className = null;
						this.$refs[aRefId][0].text = 'See less';
					} else {
						this.$refs[divRefId][0].className = 'truncate';
						this.$refs[aRefId][0].text = 'See more';
					}
				} else {
					let className = elementArray.className;
					if (className === 'truncate') {
						this.$refs[divRefId].className = null;
						this.$refs[aRefId].text = 'See less';
					} else {
						this.$refs[divRefId].className = 'truncate';
						this.$refs[aRefId].text = 'See more';
					}
				}
			}
		},
		isEllipsisActive(divRefId) {
			let elementArray = this.$refs[divRefId];
			if (elementArray) {
				if (elementArray instanceof Array) {
					let element = elementArray[0];
					return element.offsetWidth < element.scrollWidth;
				} else {
					return elementArray.offsetWidth < elementArray.scrollWidth;
				}
			}
			return false;
		},

		getPercentageOf(value, total) {
			return (value / total) * 100;
		},

		showImage(url) {
			let fileName = FileUtil.getUrlFileName(url);
			EventBus.$emit('onSelectImageView', {
				url: url,
				name: fileName
			});
			this.$bvModal.show('image-view-dialog');
		},

		hasMedia(media) {
			return media && media.url && !_.isEmpty(media.url);
		},

		hasUrl(url) {
			return url && !_.isEmpty(url);
		},
	},
};
</script>

<style scoped>
.repair-count {
	font-size: 5em;
	/* font-weight: bold; */
}
</style>



